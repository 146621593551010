<template>
  <div>
    <v-dialog
      v-model="formOpened" 
      fullscreen hide-overlay persistent
      transition="slide-full-modal-transition" 
      content-class="j_modal"
    >
      <v-card>
        <div class="modal__header full_title pd_left_3">
          <button class="hamberger_menu" type="button"  @click="onAction('cancel')">
            <v-icon>mdi-menu</v-icon>
          </button>
          <span>Dash Board <span class="sub_title">{{ `(${dashboardItem.idx} / ${dashboardItem.navIdx}) ` }} {{ dashboardItem.name || '' }}</span></span>
        </div>
        <v-card-text class="modal__formControl dark_theme type3 properties4">
          <!-- <v-form v-model="valid" ref="form"> -->
            <v-container>
              <v-card-actions class="modal_toolbar">
                <div class="tool_menus">
                  <div
                    data-dashboard-quickbtn="dashboard"
                    title="page"
                    :class="catCode == __C_.PAGE_COMPONENT.TYPE_PAGE ? 'selected': ''"
                    @click="onDashboardTypeChange(__C_.PAGE_COMPONENT.TYPE_PAGE)"
                  />
                  <div
                    data-dashboard-quickbtn="modal"
                    title="modal"
                    :class="catCode == __C_.PAGE_COMPONENT.TYPE_MODAL ? 'selected': ''"
                    @click="onDashboardTypeChange(__C_.PAGE_COMPONENT.TYPE_MODAL)"
                  />
                  <div
                    data-dashboard-quickbtn="pdf"
                    title="pdf"
                    :class="catCode == __C_.PAGE_COMPONENT.TYPE_PDF ? 'selected': ''"
                    @click="onDashboardTypeChange(__C_.PAGE_COMPONENT.TYPE_PDF)"
                  />
                </div>
              </v-card-actions>
              
              <v-card-actions class="modal_toolbar_extended">
                <div class="tool_menus menu_type_second menu_type_charts">
                  <div>
                    <div
                      class="dashboard"
                      data-dashboard-quickbtn="donut_chart"
                      title="donut"
                      :class="chartCode == __C_.CHART.CAT_CODE_DONUT ? 'selected': ''"
                      @click="() => { onChartTypeSelect(__C_.CHART.CAT_CODE_DONUT) }"
                  ></div>
                  </div>
                  <div>
                    <div
                      class="dashboard"
                      data-dashboard-quickbtn="pie_chart"
                      title="pie"
                      :class="chartCode == __C_.CHART.CAT_CODE_PIE ? 'selected': ''"
                      @click="() => { onChartTypeSelect(__C_.CHART.CAT_CODE_PIE) }"
                  ></div>
                  </div>
                  <div>
                    <div
                      class="dashboard"
                      data-dashboard-quickbtn="axes_chart"
                      title="axes"
                      :class="chartCode == __C_.CHART.CAT_CODE_BAR_AXIS ? 'selected': ''"
                      @click="() => { onChartTypeSelect(__C_.CHART.CAT_CODE_BAR_AXIS) }"
                  ></div>
                  </div>
                  <div>
                    <div
                      class="dashboard"
                      data-dashboard-quickbtn="solid_chart"
                      title="solid"
                      :class="chartCode == __C_.CHART.CAT_CODE_BAR_SOLID ? 'selected': ''"
                      @click="() => { onChartTypeSelect(__C_.CHART.CAT_CODE_BAR_SOLID) }"
                  ></div>
                  </div>
                  <div>
                    <div
                      class="dashboard"
                      data-dashboard-quickbtn="progress_chart"
                      title="progress"
                      :class="chartCode == __C_.CHART.CAT_CODE_BAR_PROGRESS ? 'selected': ''"
                      @click="() => { onChartTypeSelect(__C_.CHART.CAT_CODE_BAR_PROGRESS) }"
                    ></div>
                  </div>
                  <div>
                    <div
                      class="dashboard"
                      data-dashboard-quickbtn="delta_bar_chart"
                      title="delta"
                      :class="chartCode == __C_.CHART.CAT_CODE_BAR_DELTA ? 'selected': ''"
                      @click="() => { onChartTypeSelect(__C_.CHART.CAT_CODE_BAR_DELTA) }"
                    ></div>
                  </div>
                  <div>
                    <div
                      class="dashboard"
                      data-dashboard-quickbtn="histogram_chart"
                      title="histogram"
                      :class="chartCode == __C_.CHART.CAT_CODE_HISTORY ? 'selected': ''"
                      @click="() => { onChartTypeSelect(__C_.CHART.CAT_CODE_HISTORY) }"
                    ></div>
                  </div>
                  <div>
                    <div
                      class="dashboard"
                      data-dashboard-quickbtn="radar_chart"
                      title="radar"
                      :class="chartCode == __C_.CHART.CAT_CODE_RADAR ? 'selected': ''"
                      @click="() => { onChartTypeSelect(__C_.CHART.CAT_CODE_RADAR) }"
                    ></div>
                  </div>
                  <div>
                    <div
                      class="dashboard"
                      data-dashboard-quickbtn="icicle_chart"
                      title="icicle"
                      :class="chartCode == __C_.CHART.CAT_CODE_ICICLE ? 'selected': ''"
                      @click="() => { onChartTypeSelect(__C_.CHART.CAT_CODE_ICICLE) }"
                  ></div>
                  </div>
                </div>
                <div class="tool_menus menu_type_third">
                  <div>
                    <div
                      class="dashboard"
                      data-dashboard-quickbtn="float_table"
                      title="floating"
                      @click="() => { onChartTypeSelect('floating') }"
                  ></div>
                  </div>
                  <div>
                    <div
                      class="dashboard"
                      data-dashboard-quickbtn="summary_table"
                      title="summary"
                      :class="chartCode == __C_.CHART.CAT_CODE_TABLE_SUMMARY ? 'selected': ''"
                      @click="() => { onChartTypeSelect(__C_.CHART.CAT_CODE_TABLE_SUMMARY) }"
                  ></div>
                  </div>
                  <div>
                    <div
                      class="dashboard"
                      data-dashboard-quickbtn="skyline_table"
                      title="skyline"
                      :class="chartCode == __C_.CHART.CAT_CODE_SKYLINE ? 'selected': ''"
                      @click="() => { onChartTypeSelect(__C_.CHART.CAT_CODE_SKYLINE) }"
                    />
                  </div>
                  <div>
                    <div
                      class="dashboard"
                      data-dashboard-quickbtn="data_table"
                      title="data table"
                      @click="() => { onChartTypeSelect('data') }"
                  ></div>
                  </div>
                  <div>
                    <div
                      class="dashboard"
                      data-dashboard-quickbtn="custom_chart"
                      title="custom chart"
                      :class="chartCode == __C_.CHART.CAT_CODE_CUSTOM ? 'selected': ''"
                      @click="() => { onChartTypeSelect(__C_.CHART.CAT_CODE_CUSTOM) }"
                    />
                  </div>
                </div>
              </v-card-actions>
              <div class="modal_dark_btn has_extra_properties">
                <j-button
                  class="type01 sky  type_full"
                  :class="{ disabled: !resetable }"
                  :disabled="!resetable"
                  @click="onAction('save')"
                >Save</j-button>
                <j-button v-if="modeMod" class="type01 delete  type_full" @click="onAction('delete')">Delete</j-button>
                <div class="check_wrapper">
                  <v-checkbox v-model="update" :label="'Update'"/>
                  <v-checkbox :label="'Page Svg'" />
                  <v-checkbox :label="'Fixed'" />
                </div>
                <button class="help" @click="(e) => { helper=!helper }"></button>
              </div>
              <div class="modal__formControl_inside dark_theme">
                <v-tabs v-model="stepper" class="wrap__modal_wfix" @change="onTabChange">
                  <v-tab data-type="navigation" title="navigation" @click="sideTabOpened=false"></v-tab>
                  <v-tab data-type="database" title="database" @click="sideTabOpened=false"></v-tab>
                  <v-tab data-type="layout" title="layout" @click="sideTabOpened=false"></v-tab>
                  <v-tab data-type="containers" title="containers" @click="sideTabOpened=false"></v-tab>

                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-sysenv-tab-general ref="general" @import-item="onImport" />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-sysenv-tab-database ref="database" type="dashboard" />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-sysenv-tab-layout />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-sysenv-tab-container @controller-select="onChartPickedUp" />
                  </v-tab-item>
                </v-tabs>

                <div class="chart__property_side_tab" :class="classTabSlideOpened">
                  <div class="header__">
                    <span>{{ chartInfo.title }}</span>
                    <small>J-Chart Library</small>
                  </div>
                  <div 
                    class="list_header__title" 
                    style="font-size: 10px; font-weight: 500; margin-bottom: 15px;"
                  >
                    {{ chartInfo.rows }} ITEM(s) FOUND
                  </div>

                  <div 
                    v-for="chartMeta in chartItemList" 
                    style="display: flex; justify-content: center;"
                    :key="chartMeta.index"
                  >
                    <a v-ripple @click="onChartPickedUp(chartMeta)">
                      <div class="chart_item_list">
                        <span class="chart_no">{{ chartMeta.ChartNo }}</span>
                        <span class="chart_name">{{ chartMeta.Name }}</span>
                        <j-chart-svg style="cursor: pointer;" :width="180" :xmlString="chartMeta.XmlString" />
                      </div>
                    </a>
                  </div>
                </div>

                <div id="j-dashboard">
                  <div
                  class="canvasAreaLine"
                  :style="`--area-width:${dimention.width+1}px; --area-height: ${dimention.height}px`"
                  >
                    <div class="dashboard_builder_layout_header__">
                      <div>
                        <h5 style="color: #454545;">
                          <small><a @click="resetContainer">Reset</a></small>
                        </h5>
                      </div>
                      <div>
                        <label class="label_chart_fixed">
                          <j-check-box v-model="chartFixed" true-value="Y" false-value="N" /> Fixed
                        </label>
                      </div>
                    </div>
                    <j-dashboard-layout-builder
                      ref="layoutBuilder"
                      :mode="__C_.DASHBOARD.BUILDER_MODE_LAYOUT"
                      :layout="dashboardItem.layoutAttrs"
                      :containers="dashboardItem.containerAttrs"
                      :chart-meta-supply="chartMetaSupplied"
                      :chart-items="chartItems"
                      :chart-item-updated="chartItemUpdated"
                      :movable="chartMovable"
                      @chart-click="onChartClicked"
                      @request-modal="onModalRequested"
                      @resize="onResize"
                    />
                  </div>
                </div>
              </div>
            </v-container>
          <!-- </v-form> -->
        </v-card-text>
      </v-card>
    </v-dialog>

    <j-alert
      v-model="msgOpen"
      :type="msgInfo.type"
      :title="msgInfo.title"
      :title-description="msgInfo.titleDescription"
      :message="msgInfo.message"
      :button="msgInfo.button"
      :button-text="msgInfo.buttonText"
      @yes="yes()"
      @cancel="msgOpen = false"
    ></j-alert>

    <component 
      v-model="mFormOpened"
      :is="mFormComponent"
      :key-value="mKeyValue"
      @updated="onUpdated"
    />

  </div>
</template>

<script>
import __C from '@/primitives/_constant_'
import Vue from "vue"
import { mapState, mapMutations, mapGetters, mapActions } from "vuex"
import { createSimpleTransition } from 'vuetify/es5/util/helpers'
import { ChartLibraryService } from "@/services"
import { SafeIdMixin } from '@/mixins/safeid.mixin'
import DashboardData from '@/primitives/_dataformDashboardBuilder'
import JDashboardLayoutBuilder from '@/components/JDashboardLayoutBuilder'
import JSysenvChartModals from '@/components/JSysenvChartModals'
import JSysenvTabProps from './SysenvPageDashboard'

Vue.component('slide-full-modal-transition', createSimpleTransition('slide-full-modal-transition'))

export default {
  name: 'sysenv-intended-datagrid-modal',
  mixins: [SafeIdMixin],
  components: {
    JDashboardLayoutBuilder,
    ...JSysenvChartModals,
    ...JSysenvTabProps,
  },
  props: {
    itemKey: null,
    value: { type: Boolean, default: false }
  },
  data: () => ({
    // Service Instances -------------
    chartLibraryService: null,
    // activators --------------------
    msgOpen: false,
    // general -----------------------
    chartCode: '',
    chartInfo: { title: '', subTitle: '', rows: 0 },

    // ### chartItemList ### ---------
    // Chart Selection List, it is just for picking a chart up to add a
    // chart to the Dashboard under the Dashboard-Builder 'Builder' Mode.
    chartItemList: [],

    // ### chartItems ### ------------
    // If a chart is selected, some crucial informations are extracted
    // from it, and then it will be sent to the Dashboard Builder using 
    // 'chartMetaSupplied'. 'chartMetaSupplied' works on Builder Mode
    // as a deliverer.
    chartMetaSupplied: null,

    // ### chartItems ### ------------
    // It's the whole information of the charts that will be included
    // or already included in a Dashboard. If a chart is picked up from 
    // the chart selection, it will be added to the chartItems in new.
    // Or, if the charts are already existed (new added before) in the
    // Dashboard, whole the information of them is retrieved along with
    // the Dashboard information.
    //
    // Note.
    // In the service mode (Dashboard-Builder 'Viewer' Mode), new added
    // chart is not existed, they won't be added in new. Only the chart
    // information the Dashboard already has is using 'chartItems' provided
    // to the Viewer.
    chartItems: [],

    chartItemUpdated: {},
    chartMovable: false,

    dimention: {},
    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Yes", "No", "Cancel"]
    },
    sideTabOpened: false,
    stepper: 0,
    update: true,
    valid: false,


    // For the modal components
    mFormOpened: false,
    mFormComponent: null,
    mKeyValue: null,
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']),
    ...mapState(__C.STORE_NAMESPACE.DASHBOARD_ADMIN, ['dashboardItem']),
    ...mapGetters(__C.STORE_NAMESPACE.DASHBOARD_ADMIN, ['general']),

    __C_() { return __C },

    chartFixed: {
      get() { return this.chartMovable ? 'N' : 'Y' },
      set(val) { this.chartMovable = val == 'Y' ? false : true }
    },
    classTabSlideOpened() { return this.sideTabOpened ? 'open' : 'close' },

    codePropagated: {
      get() { return this.$store.state.sysenv.codePropagated },
      set(value) { this.$store.commit('sysenv/codePropagate', value) }
    },
    modeNew() { return this.formMode == __C.FORM.EDIT_MODE_NEW },
    modeMod() { return this.formMode == __C.FORM.EDIT_MODE_MOD },
    formMode() {
      if (this.itemKey) return __C.FORM.EDIT_MODE_MOD
      else return __C.FORM.EDIT_MODE_NEW
    },
    formOpened: {
      get() { return this.value },
      set(val) { this.$emit('input', val) }
    },
    updated: {
      get() { return this.$store.state.sysenv.menuUpdated },
      set(value) { this.$store.commit("sysenv/setMenuStatusUpdated", value) }
    },
    catCode() { return this.dashboardItem.catCode || 'Page' },
    typeCode() { return this.dashboardItem.typeCode || '' },
    helperUrl() { return this.chartItem.ChartNo && this.helper ? require(`../../../assets/helpDoc/logo.png`) : '' },

  },
  watch: {
    formOpened(val) {
      if (!val) return

      this.init()

      if (this.modeNew) {
        this.onDashboardTypeChange(__C.PAGE_COMPONENT.TYPE_PAGE)
        this.setDashboardItem({
          catCode: __C.PAGE_COMPONENT.TYPE_PAGE,
          typeCode: '',
          containerAttrs: [],
          layoutAttrs: DashboardData.layout,
          moreAttrs: DashboardData.more,
        })
        this.$refs.general.setData(this.formMode)

      } else {
        this.getDashboardItem({idx: this.itemKey, catCode: this.catCode}).then((chartItems) => {
          this.chartItems = chartItems
          this.$refs.general.setData(this.formMode)
        })
      }
    }
  },
  created() {
    this.chartLibraryService = new ChartLibraryService()
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.DASHBOARD_ADMIN, [
      'setControllerRoleNo', 'setDashboardItem', 'setEmpty'
    ]),
    ...mapActions(__C.STORE_NAMESPACE.DASHBOARD_ADMIN, [
      'getChartItems', 'getDashboardItem', 'putDashboardItem', 'updDashboardItem', 'delDashboardItem'
    ]),
    
    // CRUD Process
    onAction(action) {
      if (action == 'save') this.modeNew ? this.add() : this.edit()
      else if (action == 'delete') this.del()
      else this.close()
    },
    onChartClicked(chart) {
      this.setControllerRoleNo(chart.meta.roleNo)
    },
    onChartPickedUp(chartMeta) {
      let isController = (chartMeta.Role == __C.DASHBOARD.SVG_ROLE_CONTROLLER)

      if(isController) {
        // In case of the Controller, every single contoller (chart) should be added as a new.
        this.chartItems.push(chartMeta)
        this.chartMetaSupplied = { id: this.safeId(''), ...chartMeta }

      } else {
        // Chart already exists.
        if(this.chartItems.findIndex(item => chartMeta.ChartNo == item.ChartNo) >= 0) {
          this.chartMetaSupplied = { id: this.safeId(''), ...chartMeta }
          
        // Chart not existed.
        } else {
          this.chartLibraryService.getChart(chartMeta.CatCode, chartMeta.ChartNo).then(res => {
            this.chartItems.push(res)
            this.chartMetaSupplied = { id: this.safeId(''), ...chartMeta }
          })
        }
      }
    },
    onChartTypeSelect(catCode) {
      this.sideTabOpened = true
      this.chartCode = catCode
      this.chartInfo.title = __C.CHART.CAT_CODE_NAME[catCode]
      this.getChartItems({
        catCode: catCode,
        phaseCode: this.dashboardItem.phaseCode,
        groupCode: this.dashboardItem.groupCode,
        itemCode: this.dashboardItem.itemCode,
      }).then(items => {
        if (!items || items.length === 0) {
          this.chartItemList = []
          this.chartInfo.rows = 0
        } else {
          this.chartItemList = items
          this.chartInfo.rows = items.length
        }
      })
    },
    onResize(d) {
      this.dimention = {
        width: Math.round(d.width),
        height: Math.round(d.height)
      }
      this.setDashboardItem({ layoutAttrs: { ...this.dashboardItem.layoutAttrs, dimention: this.dimention }})
    },
    onDashboardTypeChange(type) {
      this.closeSideTab()
      this.setDashboardItem({ catCode: type })
    },
    onImport(idx) {
      this.getDashboardItem({idx: idx, catCode: this.catCode}).then((chartItems) => {
        this.chartItems = chartItems
        this.$refs.general.setData(__C.FORM.EDIT_MODE_MOD)
      })

      // // this.datagridItem.idx : original id of the data owner
      // let idx___ = this.datagridItem.idx
      // let primeData = { 
      //   idx: this.datagridItem.idx,
      //   phaseCode: this.datagridItem.phaseCode,
      //   groupCode: this.datagridItem.groupCode,
      //   itemCode: this.datagridItem.itemCode,
      //   subItemCode: this.datagridItem.subItemCode,
      //   version: 'v0.0.1'
      // }
      
      // this.getDatatable(idx).then(res => {
      //   if(res.idx == idx___) delete primeData.version
      //   // Needs to time-delay to wait for the previous $refs.general.setData
      //   // process is finished in the getChart(code) process.
      //   setTimeout(() => {
      //     if(this.modeMod) this.setDatagridItem(primeData)
      //     this.$refs.general.setData(this.formMode)
      //   }, 500)
      // })
    },
    onModalRequested(chartInfo) {
      this.mFormComponent = __C.CHART.CAT_CODE_COMPONENT[chartInfo.catCode]
      // Needs little delay time to be done creating the component
      // before running.
      setTimeout(() => {
        this.mKeyValue = chartInfo.chartNo
        this.mFormOpened = true
      })
    },
    onTabChange(tabIndex) {
      this.closeSideTab()
    },
    onUpdated(item) {
      let item_ = JSON.parse(JSON.stringify(item))
      this.chartItemUpdated = item

      // just update the current displayed chart item, not to 
      // update database, it has already updated.
      this.chartItems.forEach((item___, i) => {
        if(item_.ChartNo == item___.ChartNo) this.chartItems[i] = item_
      })
    },

    add() {
      let reqItem = JSON.parse(JSON.stringify(this.dashboardItem))
      this.putDashboardItem().then(res => {
        // if(res.NextNo.CHART_NO) {
        //   this.setDashboardItem({ 
        //     idx: res.NextNo.IDX
        //   })
        // }

        this.close()
        // this.$emit('key-value-update', res.NextNo.CHART_NO)
        this.$emit("updated", true)
        this.menuUpdated = true
      })
    },
    edit() {
      this.updDashboardItem().then(res => {
        this.$emit("updated", true)
      })
    },
    del() {
      this.yes = () => {
        this.msgOpen = false
        this.delDashboardItem().then(res => {
          this.close()
          this.$emit("updated", true)
        })
        this.yes = () => { }
      }
      this.msgInfo.type = "WARN"
      this.msgInfo.title = "Action Approval"
      this.msgInfo.titleDescription = "Important Notification"
      this.msgInfo.message = "Do you want to delete current Dashboard?"
      this.msgInfo.buttonText[0] = "Delete"
      this.msgOpen = true
    },
    init() {
      this.stepper = 0
      // every time came into the modal, either the mode new or edit,
      // whole the store's data should be initialized.
      this.setEmpty()
      this.chartItemList = []

      this.chartInfo = { title: '', subTitle: '', rows: 0 }
      this.chartMetaSupplied = null
      this.chartMovable = false

      this.dimention = {}
      this.sideTabOpened = false

      this.$refs.layoutBuilder.init()
    },
    close() {
      this.formOpened = false
    },
    closeSideTab() {
      this.sideTabOpened = false
    },
    resetContainer() {
      this.$refs.layoutBuilder.reset()
    },
    resetable() {
      return true
      // if (!this.dashboardItem) return true
      // if (this.modeNew) {
      //   return true
      // } else {
      //   return true
      // }
    },
  }
}
</script>
<style lang="stylus" scoped>
.chart_item_list {
  display: flex; 
  flex-direction: column;
  align-items: center;
  color: #fff;

  .chart_no {
    font-weight: 300;
    font-size: .9rem;
    opacity: .3;
  }
  .chart_name {
    font-size: 1.1rem;
    margin-bottom: -.7rem;
  }
}
</style>

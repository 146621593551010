<template>
  <v-flex v-model="bar_general" class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Container</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div class="chart_properties_contents">
      <j-alert
        v-model="msgOpen"
        :title="msgInfo.title"
        :type="msgInfo.type"
        :message="msgInfo.message"
        :button="msgInfo.button"
        :button-text="msgInfo.buttonText"
        @yes="yes()"
        @cancel="msgOpen = false"
      />
      <div class="header">
        <div class="row">
          Containers
          <div class="input_flex_wrap code_title_wrapper" style="padding-left: 1rem; font-size: 90%;">
            <div class="jcon_temp_plus" data-grid-quickbtn="btn" @click="appendAttr()">
              <label>Add</label>
            </div>
          </div>
        </div>
        <a class="expand" @click="collapsed['containers'] = !collapsed['containers']">
          <v-icon v-if="collapsed['containers']">mdi-chevron-down</v-icon>
          <v-icon v-else>mdi-chevron-up</v-icon>
        </a>
      </div>
      <v-flex class="control color container_wrapper" v-show="collapsed['containers']">
        <div class="type_common_input">
          <span class="label">Show All</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input v-model="showAll" type="radio" name="showAll" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <div style="height: 2rem;" />
      
        <div
          v-for="(container, i) in localContainerAttrs"
          v-if="showContainer(i)"
          style="padding-top: 4px; margin-top: 4px;"
          :key="container.index"
        >
          <div class="type_common_input type_color_float">
            <div class="input_flex_wrap">
              <div class="jcon_temp_minus" data-grid-quickbtn="btn" @click="clickRemoveBtn(i)" />
              <div style="display: flex; width: 100%; padding-left: 5px;">
                <label 
                  style="margin-right: 10px;"
                  :style="selectedContainer(i)"
                >{{ i+1 }}{{ surfix(i) }} Container</label>
              </div>
            </div>
          </div>
          <div class="type_inner_more">
            <label class="label">Title</label>
            <input
              v-model="container.title"
              type="text"
              class="type100"
              placeholder="Container Title"
              @focusout="updateAttr"
            />
            <div class="type_inner">
              <div class="type_common_select">
                <label class="type_gray second_depth">Font</label>
                <select v-model="container.font" dir="rtl" @input="(e) => { updateAttr(e.target.value, i, 'font') }">
                  <option
                    v-for="option in fontOptions"
                    :key="option.index"
                    :value="option.value"
                  >{{option.text}}</option>
                </select>
              </div>
              <div class="type_common_select">
                <label class="type_gray second_depth">Style</label>
                <select v-model="container.fontStyle" dir="rtl" class="type70" @input="(e) => { updateAttr(e.target.value, i, 'fontStyle') }">
                  <option
                    v-for="option in fontStyleOptions"
                    :key="option.index"
                    :value="option.value"
                  >{{option.text}}</option>
                </select>
              </div>
              <div class="type_common_input type_color_float type_gray">
                <label>Size / Color</label>
                <div class="input_flex_wrap_end">
                  <input v-model="container.fontSize"   type="text" @input="updateAttr" />
                  <j-color-picker v-model="container.color" @input="updateAttr"></j-color-picker>
                </div>
              </div>
              <div class="type_common_input type_color_float type_gray">
                <label>Coordinates</label>
                <div class="input_flex_wrap_end">
                  <input v-model="container.titleX" type="text" @input="updateAttr"/><div style="width: 3px;"></div>
                  <input v-model="container.titleY" type="text" @input="updateAttr"/>
                </div>
              </div>
            </div>

            <div style="height: 1.5rem;" />

            <label>Position</label>
            <div class="type_inner">
              <div class="type_common_input type_gray">
                <label>
                  Column
                  <small style="padding-left: 3px;">(Start, Span)</small>
                </label>
                <input
                  v-model="container.column.start"
                  type="text"
                  class="type_double"
                  @input="updateAttr"
                />
                <input
                  v-model="container.column.span"
                  type="text"
                  @input="updateAttr"
                />
              </div>
              <div class="type_common_input type_gray">
                <label>
                  Row
                  <small style="padding-left: 3px;">(Start, Span)</small>
                </label>
                <input
                  v-model="container.row.start"
                  type="text"
                  class="type_double"
                  @input="updateAttr"
                />
                <input v-model="container.row.span" type="text" @input="updateAttr" />
              </div>
            </div>

            <div style="height: 2rem;" />

            <div class="type_common_input">
              <label>Width</label>
              <input v-model="container.width" type="text" @input="updateAttr" />
            </div>
            <div class="type_common_input">
              <label>Height</label>
              <input v-model="container.height" type="text" @input="updateAttr" />
            </div>
            <div class="type_common_input type_color_float">
              <label>Border Weight / Color</label>
              <div class="input_flex_wrap_end">
                <input v-model="container.borderWeight" type="number" @input="updateAttr" />
                <j-color-picker v-model="container.borderColor" @input="updateAttr"></j-color-picker>
              </div>
            </div>
            <div class="type_common_input">
              <j-color-picker v-model="container.fillColor" title="Fill Color" @input="updateAttr"></j-color-picker>
            </div>

            <div style="height: 2rem;" />

            <label v-html="'Controller&nbsp;<small>(SVG System Compoment)</small>'" />
            <div class="column align_end">
              <select 
                v-model="container.control" 
                class="type100" 
                @change="(e) => { onAppendController(e.target.value) }"
              >
                <option value=""></option>
                <option
                  v-for="option in controllerOptions"
                  :key="option.index"
                  :value="option.code"
                >{{option.name}}</option>
              </select>
            </div>
            <div class="type_inner">
              <div 
                v-for="(item, j) in container.items" 
                v-if="item.role == 'controller'" 
                class="controller_wrapper" 
                :key="item.index"
              >
                <div class="type_common_input" style="margin-bottom: 3px;">
                  <label class="type_gray second_depth" :style="selectedController(item.roleNo)">Name</label>
                  <input v-model="item.name" style="width: 13.5rem; text-align: left;" type="text" />
                </div>
                <div class="type_common_input type_gray">
                  <span class="label" :style="selectedController(item.roleNo)">Config</span>
                  <div class="row">
                    <div justify="center" style="align-items: center">
                      <v-btn 
                        class="type_openTextarea" 
                        style="width: 13.5rem;" 
                        @click.stop="editorOpen(i, j, 'config')"
                      >
                        {{ getQueryParts(i, j, 'config') }}
                      </v-btn>
                      <j-modal-scripter 
                        v-model="item.config" 
                        title="Controller Configuring Properties"
                        sub-title="JSON Format"
                        :buttons="{validate: true, save:false}"
                        :message="validated[i][j]['config']['message']"
                        :opened="editorOpended[i][j]['config']"
                        :valid="validated[i][j]['config']['valid']"
                        @close="editorOpen(i, j, 'config')"
                        @validate="onValidate(i, j, 'config')"
                      />
                    </div>
                  </div>
                </div>
                <div class="type_common_input type_gray">
                  <span class="label" :style="selectedController(item.roleNo)">Action</span>
                  <div class="row">
                    <div justify="center" style="align-items: center">
                      <v-btn 
                        class="type_openTextarea" 
                        style="width: 13.5rem;" 
                        @click.stop="editorOpen(i, j, 'action')"
                      >
                        {{ getQueryParts(i, j, 'action') }}
                      </v-btn>
                      <j-modal-scripter 
                        v-model="item.action" 
                        title="Controller Action Properties"
                        sub-title="JSON Format"
                        :buttons="{validate: true, save:false}"
                        :message="validated[i][j]['action']['message']"
                        :opened="editorOpended[i][j]['action']"
                        :valid="validated[i][j]['action']['valid']"
                        @close="editorOpen(i, j, 'action')"
                        @validate="onValidate(i, j, 'action')"
                      />
                    </div>
                  </div>
                </div>
                <div class="type_common_input type_gray">
                  <span class="label" :style="selectedController(item.roleNo)">Sql</span>
                  <div class="row">
                    <div justify="center" style="align-items: center">
                      <v-btn 
                        class="type_openTextarea" 
                        style="width: 13.5rem;" 
                        @click.stop="editorOpen(i, j, 'sql')"
                      >
                        {{ getQueryParts(i, j, 'sql') }}
                      </v-btn>
                      <j-modal-scripter 
                        v-model="item.sql" 
                        title="Controller Query"
                        sub-title="For the action provider"
                        :buttons="{validate: true, save:false}"
                        :message="validated[i][j]['sql']['message']"
                        :opened="editorOpended[i][j]['sql']"
                        :valid="validated[i][j]['sql']['valid']"
                        @close="editorOpen(i, j, 'sql')"
                        @validate="onValidate(i, j, 'sql')"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div style="height: 2.5rem;" />

          </div>
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import '@/assets/stylus/ui/component/_jSysenvTabContainer.styl'

import __C from '@/primitives/_constant_'
import { mapState, mapMutations } from "vuex"
import { ChartLibraryService, SystemCodeService } from "@/services"
import { QueryValidationMixin } from "@/mixins/sqlValidator"
import StaticOptions from '@/mixins/staticOptions'
import DashboardData from '@/primitives/_dataformDashboardBuilder'

export default {
  name: 'j-chart-tab-svg',
  mixins: [
    QueryValidationMixin,
    StaticOptions
  ],
  data: () => ({
    // Service Instances -------------
    chartLibraryService: null,
    systemCodeService: null,

    bar_general: null,
    collapsed: {
      general: true,
      containers: true,
    },
    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Delete", "No", "Cancel"]
    },
    currentIcon: null,

    controllerOptions: [],
    localContainerAttrs: [],
    showAll: 'N',

    editorOpended: [],
    validated: [],

    msgOpen: false,
    yes: null
  }),
  watch: {
    'dashboardItem.containerAttrs': {
      handler(val) {
        this.localContainerAttrs = []
        if (!val || Object.keys(val).length === 0) return
        
        this.localContainerAttrs = JSON.parse(JSON.stringify(val))
        this.setContainerEnv()
      },
      deep: true
    }
  },
  computed: {
    ...mapState(__C.STORE_NAMESPACE.DASHBOARD_ADMIN, [
      'dashboardItem', 
      'containerIndex',
      'controllerRoleNo'
    ]),
  },
  created() {
    this.chartLibraryService = new ChartLibraryService()
    this.systemCodeService = new SystemCodeService()
  },
  mounted() {
    this.systemCodeService.chartOptions(__C.CHART.CAT_CODE_COMPLIB_SVG_CHART_CONTROLLER, 'ALL', res => {
      this.controllerOptions = res
    })
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.DASHBOARD_ADMIN, [
      'setDashboardItem',
      'setContainerAdded'
    ]),

    onAppendController(chartNo) {
      // Append a controller, it doesn't matter same chart already exists or not.

      if(!chartNo) return

      this.chartLibraryService.getChart(__C.CHART.CAT_CODE_COMPLIB_SVG_CHART_CONTROLLER, chartNo).then(res => {
        res.Role = __C.DASHBOARD.SVG_ROLE_CONTROLLER
        res.RoleNo = `${res.ChartNo}_${Math.floor(Date.now())}`
        this.$emit('controller-select', res)
      })
    },
    onValidate(i, j, name) {
      let validated_ = JSON.parse(JSON.stringify(this.validated))

      if(name == 'sql') {
        this.validateSql(this.localContainerAttrs[i].items[j][name]).then(res => {
          validated_[i][j][name] = res
        })

      } else {
        validated_[i][j][name] = this.validateJson(this.localContainerAttrs[i].items[j][name])

        if(validated_[i][j][name].valid) {
          this.localContainerAttrs[i].items[j][name] = validated_[i][j][name].json

          // Updating will be ran on closing, because of auto closing after updateAttr.
          // this.updateAttr()
        }
      }
      
      this.validated = validated_
    },

    appendAttr() {
      /* Add a container */

      let container_ = JSON.parse(JSON.stringify(DashboardData.container))
      container_.containerNo = `container_${Math.floor(Date.now())}`

      this.localContainerAttrs.push(container_)
      this.editorOpended.push(false)
      this.validated.push({ valid: true, message: '' })

      this.updateAttr()
      this.setContainerAdded(this.localContainerAttrs.length-1)
    },
    clickRemoveBtn(i, j, el) {
      this.yes = () => {
        this.msgOpen = false
        this.removeAttr(i, j, el)
        this.yes = () => {}
      }
      this.msgInfo.type = "WARN"
      this.msgInfo.title = "Remove Style"
      this.msgInfo.message = "Do you want to delete this style?"
      this.msgInfo.buttonText[0] = "Delete"
      this.msgOpen = true
    },
    getQueryParts(i, j, name) {
      if(!this.localContainerAttrs[i].items[j][name]) return ''
      return this.localContainerAttrs[i].items[j][name].substring(0, 28) + ' ...'
    },
    editorOpen(i, j, name) {
      let opened_ = JSON.parse(JSON.stringify(this.editorOpended))
      opened_[i][j][name] = !opened_[i][j][name]

      // // For updating the changes on validating, because of auto closing
      // // after updateAttr.
      if(!opened_[i][j][name]) this.updateAttr()

      this.editorOpended = opened_

      return this.editorOpended[i][j][name]
    },
    removeAttr(index) {
      /* Remove a container including its all items */

      this.localContainerAttrs[index] = null
      this.editorOpended[index] = null
      this.validated[index] = null

      this.localContainerAttrs = this.localContainerAttrs.filter(v => !!v)
      this.editorOpended = this.editorOpended.filter(v => !!v)
      this.validated = this.validated.filter(v => !!v)

      this.updateAttr()


      /* ### The process for removing each item runs in the DASHBOARD-BUILDER. ### */

    },
    selectedContainer(i) {
      return this.containerIndex === i ? 'color: #f48fb1;' : ''
    },
    selectedController(no) {
      return this.controllerRoleNo == no ? 'color: #f48fb1;' : ''
    },
    setContainerEnv() {
      this.editorOpended = []
      this.validated = []
      
      this.localContainerAttrs.forEach((container, i) => {
        this.editorOpended.push([])
        this.validated.push([])

        container.items.forEach(() => {
          this.editorOpended[i].push({ 
            config: false, 
            action: false, 
            sql   : false 
          })
          this.validated[i].push({ 
            config: { valid: true, message: '' }, 
            action: { valid: true, message: '' }, 
            sql   : { valid: true, message: '' } 
          })
        })
      })
    },
    showContainer(i) {
      return this.showAll == 'Y' || this.containerIndex === i
    },
    surfix(i) {
      let surfix_ = ['st', 'nd', 'rd', 'th']
      if (i < 3) return surfix_[i]
      return surfix_[3]
    },
    updateAttr(val, i, el) {
      if(el) this.localContainerAttrs[i][el] = val
      this.setDashboardItem({ containerAttrs: JSON.parse(JSON.stringify(this.localContainerAttrs)) })
    }
  }
}
</script>
